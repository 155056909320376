<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent is-vertical">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <div class="columns is-gapless">
                <div class="column">
                  <p class="title">
                    Items Authorise
                  </p>
                  <p v-if="isEditableToAuthorise"
                    class="subtitle">
                    <span>
                      <span class="has-text-weight-semibold">
                        Target Value:
                      </span>
                      <span class="has-text-success has-text-weight-semibold">
                        {{ targetValue | formatCurrency($userInfo.locale) }}
                      </span>
                    </span>
                    <span class="ml-6">
                      <span class="has-text-weight-semibold">
                        Value To Be Authorise:
                      </span>
                      <span class="has-text-weight-semibold "
                        :class="targetValueColor">
                        {{ totalSelectedItems | formatCurrency($userInfo.locale) }}
                      </span>
                    </span>
                  </p>
                  <div v-if="isEditable">
                    <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                      <input v-model="allItemSelected"
                        type="checkbox">
                      <div class="state p-info">
                        <i class="icon mdi mdi-check" />
                        <label class="has-text-info has-text-weight-semibold ">Select All Items</label>
                      </div>
                    </div>
                    <p class="has-text-danger is-size-7"
                      v-if="!allItemSelected && isEditableToAuthorise">
                      Please select all items for authorise.
                    </p>
                  </div>
                </div>
                <div class="column">
                  <div class="control is-pulled-right">
                    <div class="field-body">
                      <div class="field mr-2">
                        <div class="buttons">
                          <button class="button is-secondary tooltip is-tooltip-topright"
                            v-if="hasAssessment && !isEditable && requestAuthorityPending"
                            data-tooltip="Cancel the last request to authority"
                            @click="openCancelRequestConfirmModal()">
                            <span class="icon">
                              <i class="mdi mdi-close" />
                            </span>
                            <span>Cancel Request</span>
                          </button>
                          <button class="button is-secondary tooltip is-tooltip-topright"
                            v-if="hasAssessment && !isEditable && !requestAuthorityPending"
                            data-tooltip="Cancel the last authority"
                            @click="openCancelAuthorityConfirmModal()">
                            <span class="icon">
                              <i class="mdi mdi-close" />
                            </span>
                            <span>Cancel Authority</span>
                          </button>
                          <button class="button is-secondary tooltip is-tooltip-topright"
                            v-if="hasAssessment && !isEditable && !requestAuthorityPending && allActiveItems.length > 0"
                            data-tooltip="Revert to last authority"
                            @click="openRevertConfirmModal()">
                            <span class="icon">
                              <i class="mdi mdi-undo" />
                            </span>
                            <span>Revert to Authority</span>
                          </button>
                          <button v-if="isEditable"
                            class="button is-secondary tooltip is-tooltip-topright"
                            data-tooltip="Cancel"
                            @click="cancel()">
                            <span class="icon">
                              <i class="mdi mdi-close" />
                            </span>
                            <span>Cancel</span>
                          </button>
                          <button v-if="!isEditable && !requestAuthorityPending && allActiveItems.length > 0"
                            class="button is-info is-outlined tooltip is-tooltip-topright"
                            data-tooltip="Submit request"
                            @click="editableQuote(false)">
                            <span class="icon">
                              <i class="mdi mdi-file-send" />
                            </span>
                            <span>Request Authority</span>
                          </button>
                          <button v-if="!isEditable"
                            class="button is-info is-outlined tooltip is-tooltip-topright"
                            data-tooltip="Submit authorise"
                            @click="editableQuote(true)">
                            <span class="icon">
                              <i class="mdi mdi-playlist-check" />
                            </span>
                            <span>Authorise</span>
                          </button>
                          <button v-if="isEditable && isEditableToAuthorise"
                            @click="openAuthoriseModal()"
                            :disabled="allItemSelected === false && hasPendingRequest || selectedItems.length === 0"
                            class="button is-success tooltip is-tooltip-topright"
                            data-tooltip="Autorise selected items">
                            <span class="icon">
                              <i class="mdi mdi-playlist-check" />
                            </span>
                            <span>Confirm Authorise</span>
                          </button>
                          <button v-if="isEditable && !isEditableToAuthorise"
                            @click="request"
                            :disabled="selectedItems.length === 0"
                            class="button is-success tooltip is-tooltip-topright"
                            data-tooltip="Request selected items">
                            <span class="icon">
                              <i class="mdi mdi-file-send" />
                            </span>
                            <span>Confirm Request Authority</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tabs is-boxed">
                <ul>
                  <li v-show="showTab('RR')"
                    :class="{ 'is-active' : activeTab === itemCategoryTypes.RR }"><a @click="tabClicked(itemCategoryTypes.RR)">
                      <span>
                        <span class="has-badge-child-offset2">R&amp;R</span>
                        <span v-if="rrItems.length > 0"
                          class="has-badge-rounded has-badge-medium has-badge-info"
                          :data-badge="rrItems.length" />
                      </span>
                    </a></li>
                  <li v-show="showTab('RWA')"
                    :class="{ 'is-active' : activeTab === itemCategoryTypes.RWA }">
                    <a @click="tabClicked(itemCategoryTypes.RWA)">
                      <span>
                        <span class="has-badge-child-offset2">RWA</span>
                        <span v-if="rwaItems.length > 0"
                          class="has-badge-rounded has-badge-medium has-badge-info"
                          :data-badge="rwaItems.length" />
                      </span>
                    </a>
                  </li>
                  <li v-show="showTab('REP')"
                    :class="{ 'is-active' : activeTab === itemCategoryTypes.REP }">
                    <a @click="tabClicked(itemCategoryTypes.REP)">
                      <span>
                        <span class="has-badge-child-offset2">Repair</span>
                        <span v-if="repairItems.length > 0"
                          class="has-badge-rounded has-badge-medium has-badge-info"
                          :data-badge="repairItems.length" />
                      </span>
                    </a>
                  </li>
                  <li v-show="showTab('PAINT')"
                    :class="{ 'is-active' : activeTab === itemCategoryTypes.PAINT }">
                    <a @click="tabClicked(itemCategoryTypes.PAINT)">
                      <span>
                        <span class="has-badge-child-offset2">Paint</span>
                        <span v-if="paintItems.length > 0"
                          class="has-badge-rounded has-badge-medium has-badge-info"
                          :data-badge="paintItems.length" />
                      </span>
                    </a>
                  </li>
                  <li v-show="showTab('FIBER')"
                    :class="{ 'is-active' : activeTab === itemCategoryTypes.FIBER }">
                    <a @click="tabClicked(itemCategoryTypes.FIBER)">
                      <span>
                        <span class="has-badge-child-offset2">Fiber</span>
                        <span v-if="fgItems.length > 0"
                          class="has-badge-rounded has-badge-medium has-badge-info"
                          :data-badge="fgItems.length" />
                      </span>
                    </a>
                  </li>
                  <li v-show="showTab('CRUSH')"
                    :class="{ 'is-active' : activeTab === itemCategoryTypes.CRUSH }">
                    <a @click="tabClicked(itemCategoryTypes.CRUSH)">
                      <span>
                        <span class="has-badge-child-offset2">Crush</span>
                        <span v-if="crushItems.length > 0"
                          class="has-badge-rounded has-badge-medium has-badge-info"
                          :data-badge="crushItems.length" />
                      </span>
                    </a>
                  </li>
                  <li v-show="showTab('CD')"
                    :class="{ 'is-active' : activeTab === itemCategoryTypes.CD }">
                    <a @click="tabClicked(itemCategoryTypes.CD)">
                      <span>
                        <span class="has-badge-child-offset2">C&amp;D</span>
                        <span v-if="cdItems.length > 0"
                          class="has-badge-rounded has-badge-medium has-badge-info"
                          :data-badge="cdItems.length" />
                      </span>
                    </a>
                  </li>
                  <li v-show="showTab('MECH')"
                    :class="{ 'is-active' : activeTab === itemCategoryTypes.MECH }">
                    <a @click="tabClicked(itemCategoryTypes.MECH)">
                      <span>
                        <span class="has-badge-child-offset2">Mech</span>
                        <span v-if="mechItems.length > 0"
                          class="has-badge-rounded has-badge-medium has-badge-info"
                          :data-badge="mechItems.length" />
                      </span>
                    </a>
                  </li>
                  <li v-show="showTab('PART')"
                    :class="{ 'is-active' : activeTab === itemCategoryTypes.PART }">
                    <a @click="tabClicked(itemCategoryTypes.PART)">
                      <span>
                        <span class="has-badge-child-offset2">Part</span>
                        <span v-if="partItems.length > 0"
                          class="has-badge-rounded has-badge-medium has-badge-info"
                          :data-badge="partItems.length" />
                      </span>
                    </a>
                  </li>
                  <li v-show="showTab('OPG')"
                    :class="{ 'is-active' : activeTab === itemCategoryTypes.OPG }">
                    <a @click="tabClicked(itemCategoryTypes.OPG)">
                      <span>
                        <span class="has-badge-child-offset2">OPG</span>
                        <span v-if="opgItems.length > 0"
                          class="has-badge-rounded has-badge-medium has-badge-info"
                          :data-badge="opgItems.length" />
                      </span>
                    </a>
                  </li>
                  <li v-show="showTab('MISC')"
                    :class="{ 'is-active' : activeTab === itemCategoryTypes.MISC }">
                    <a @click="tabClicked(itemCategoryTypes.MISC)">
                      <span>
                        <span class="has-badge-child-offset2">Misc</span>
                        <span v-if="miscItems.length > 0"
                          class="has-badge-rounded has-badge-medium has-badge-info"
                          :data-badge="miscItems.length" />
                      </span>
                    </a>
                  </li>
                  <li v-show="showTab('SUBL')"
                    :class="{ 'is-active' : activeTab === itemCategoryTypes.SUBL }">
                    <a @click="tabClicked(itemCategoryTypes.SUBL)">
                      <span>
                        <span class="has-badge-child-offset2">Sublet</span>
                        <span v-if="subletItems.length > 0"
                          class="has-badge-rounded has-badge-medium has-badge-info"
                          :data-badge="subletItems.length" />
                      </span>
                    </a>
                  </li>
                </ul>
                <button v-if="isEditableToAuthorise && ((lastQuoteAssessment?.subQuoteNo === 0 && requestAuthorityPending) || lastQuoteAssessment === null)"
                  @click="openEditRatesModal"
                  class="button is-primary tooltip is-tooltip-topright"
                  data-tooltip="Edit rates">
                  <span class="icon">
                    <i class="mdi mdi-pencil" />
                  </span>
                  <span>Edit Rates</span>
                </button>
              </div>
              <authorise-labour-old v-if="isLabour"
                :value="innerValue"
                :vehicle="innerVehicle"
                :next-line-number="innerValue.lines"
                :original-value="value"
                :editable="isEditable"
                :item-type="activeTab"
                :items-list="[...rrItems, ...rwaItems, ...repairItems, ...paintItems]" />
              <authorise-other-labour-old v-if="isOtherLabour"
                :value="innerValue"
                :vehicle="innerVehicle"
                :next-line-number="innerValue.lines"
                :original-value="value"
                :editable="isEditable"
                :item-type="activeTab"
                :items-list="[...mechItems, ...cdItems, ...crushItems, ...fgItems]" />
              <authorise-part-old v-if="isPart"
                :value="innerValue"
                :vehicle="innerVehicle"
                :next-line-number="innerValue.lines"
                :original-value="value"
                :editable="isEditable"
                :item-type="activeTab"
                :items-list="[...partItems]" />
              <authorise-opg-old v-if="isOpg"
                :value="innerValue"
                :vehicle="innerVehicle"
                :next-line-number="innerValue.lines"
                :original-value="value"
                :editable="isEditable"
                :item-type="activeTab"
                :items-list="[...opgItems]" />
              <authorise-misc-old v-if="isMisc"
                :value="innerValue"
                :vehicle="innerVehicle"
                :next-line-number="innerValue.lines"
                :original-value="value"
                :editable="isEditable"
                :item-type="activeTab"
                :items-list="[...miscItems]" />
              <authorise-sublet-old v-if="isSublet"
                :value="innerValue"
                :vehicle="innerVehicle"
                :next-line-number="innerValue.lines"
                :original-value="value"
                :editable="isEditable"
                :item-type="activeTab"
                :items-list="[...subletItems]" />
            </div>
          </div>
        </article>
      </div>
    </div>
    <authorise-modal v-if="isAuthoriseModalVisible"
      :show-icon="true"
      :active.sync="isAuthoriseModalVisible"
      :quote-values="innerValue"
      :vehicle-values="innerVehicle"
      :selected-items="selectedItems"
      :total-selected-items="totalSelectedItems"
      @authorise="authorise()"
      @close="isAuthoriseModalVisible = false" />
    <edit-rates-modal v-if="isEditRateModalVisible"
      :show-icon="true"
      :active.sync="isEditRateModalVisible"
      :quote-values="innerValue"
      :vehicle-values="innerVehicle"
      :selected-items="selectedItems"
      :total-selected-items="totalSelectedItems"
      @apply-rates="applyRates()"
      @close="isEditRateModalVisible = false" />
    <confirm-modal v-if="isRequestModalVisible"
      :active.sync="isRequestModalVisible"
      @ok="authorise()"
      @cancel="isRequestModalVisible = false"
      :ok-text="'Authorise'"
      :ok-class="'is-success'"
      :cancel-text="'Cancel'">
      <p slot="text-title">Confirm to request</p>
      <p slot="text-content">
        Are you sure you want to request the selected items?
      </p>
    </confirm-modal>
    <confirm-modal v-if="isRevertConfirmModalActive"
      :active.sync="isRevertConfirmModalActive"
      @ok="revertToAuthority(true)"
      @cancel="isRevertConfirmModalActive = false"
      :ok-text="'Revert to Authority'"
      :ok-class="'is-success'"
      :cancel-text="'No'">
      <p slot="text-title">Revert to Authority</p>
      <p slot="text-content">
        Are you sure you want to revert to the last authority? All changes since the last authority will be lost.
      </p>
    </confirm-modal>
    <confirm-modal v-if="isCancelAuthorityConfirmModalActive"
      :active.sync="isCancelAuthorityConfirmModalActive"
      :show-close="true"
      @ok="cancelAuthority(true)"
      @cancel="cancelAuthority(false)"
      @close="isCancelAuthorityConfirmModalActive = false"
      :cancel-text="'Discard Changes'"
      :cancel-class="'is-warning'"
      :ok-class="'is-success'"
      :ok-text="'Merge Changes'"
      :close-text="'Close'">
      <p slot="text-title">Cancel last Authority</p>
      <p slot="text-content">
        Do you want the changes made in the last authority to be merged or discarded?
      </p>
    </confirm-modal>
    <confirm-modal v-if="isCancelRequestConfirmModalActive"
      :active.sync="isCancelRequestConfirmModalActive"
      :show-close="true"
      @ok="cancelRequest(true)"
      @cancel="cancelRequest(false)"
      @close="isCancelRequestConfirmModalActive = false"
      :cancel-text="'Discard Changes'"
      :cancel-class="'is-warning'"
      :ok-class="'is-success'"
      :ok-text="'Merge Changes'"
      :close-text="'Close'">
      <p slot="text-title">Cancel last Request</p>
      <p slot="text-content">
        Do you want the changes made in the last request for authority to be merged or discarded?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import { ItemCategoryTypes, LabourTimeTypes, LabourItemCodeTypes } from '@/enums'
import {
  AuthoriseLabourOld,
  AuthoriseOtherLabourOld,
  AuthorisePartOld,
  AuthoriseOpgOld,
  AuthoriseMiscOld,
  AuthoriseSubletOld,
  AuthoriseModal,
  EditRatesModal
} from './components'
import _cloneDeep from 'lodash.clonedeep'
import StoreMixin from './storeMixin'
import { ConfirmModal } from '@/components/BulmaModal'
import { QuoteAssessmentMixin } from './mixins'
import QuoteItemsMixin from './mixins/QuoteItemsMixin'
import { NumberFiltersMixin } from '../../components/mixins/filters'
import QuoteTotalsMethodMixin from './mixins/QuoteTotalsMethodMixin'

export default {
  name: 'QuoteItemsAuhtorise',
  filters: {},
  components: {
    AuthoriseLabourOld,
    AuthoriseOtherLabourOld,
    AuthorisePartOld,
    AuthoriseOpgOld,
    AuthoriseMiscOld,
    AuthoriseSubletOld,
    ConfirmModal,
    AuthoriseModal,
    EditRatesModal
  },
  mixins: [StoreMixin, QuoteAssessmentMixin, QuoteItemsMixin, NumberFiltersMixin, QuoteTotalsMethodMixin],
  props: {
    value: null,
    vehicle: null
  },
  data() {
    return {
      innerValue: null,
      innerVehicle: null,
      activeTab: '',
      isEditable: false,
      isEditableToAuthorise: false,
      isAuthoriseModalVisible: false,
      isEditRateModalVisible: false,
      isRevertConfirmModalActive: false,
      isCancelAuthorityConfirmModalActive: false,
      isCancelRequestConfirmModalActive: false,
      isRequestModalVisible: false,
      laboursAssessments: [],
      otherLaboursAssessments: [],
      partsAssessments: [],
      opgsAssessments: [],
      miscsAssessments: [],
      subletsAssessments: [],
      ratesAssessments: [],
      requestedValue: 0
    }
  },

  computed: {
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    hasPendingRequest() {
      return this.lastQuoteAssessment?.submittedAssessment !== null && this.lastQuoteAssessment?.authorisedAssessment === null
    },
    targetValue() {
      return this.hasPendingRequest ? this.requestedValue : this.totalSelectedItems
    },
    isNtar() {
      return (
        this.value &&
        (this.value.labourType === LabourTimeTypes.NTAR || this.value.labourType === LabourTimeTypes.LTAR || this.value.labourType === LabourTimeTypes.eMTA)
      )
    },
    isLabour() {
      return (
        this.activeTab === ItemCategoryTypes.RR ||
        this.activeTab === ItemCategoryTypes.RWA ||
        this.activeTab === ItemCategoryTypes.REP ||
        this.activeTab === ItemCategoryTypes.PAINT
      )
    },
    isOtherLabour() {
      return (
        this.activeTab === ItemCategoryTypes.MECH ||
        this.activeTab === ItemCategoryTypes.CD ||
        this.activeTab === ItemCategoryTypes.CRUSH ||
        this.activeTab === ItemCategoryTypes.FIBER
      )
    },
    isPart() {
      return this.activeTab === ItemCategoryTypes.PART
    },
    isOpg() {
      return this.activeTab === ItemCategoryTypes.OPG
    },
    isMisc() {
      return this.activeTab === ItemCategoryTypes.MISC
    },
    isSublet() {
      return this.activeTab === ItemCategoryTypes.SUBL
    },
    hasAssessment() {
      return this.innerValue.quoteAssessments.length > 0
    },
    lastQuoteAssessment() {
      if (this.innerValue) {
        return this.innerValue.quoteAssessments[this.innerValue.quoteAssessments.length - 1]
      }
      return null
    },
    rrItems() {
      if (this.hasPendingRequest) {
        const submittedItems = this.lastQuoteAssessment.submittedAssessment.assessmentQuoteItems.filter((a) => a.itemType === ItemCategoryTypes.RR)
        const newItems = this.innerValue?.labours.filter((i) => i.itemType === ItemCategoryTypes.RR).filter((i) => i.isAdded === true && i.deleted === false)
        return [...submittedItems, ...newItems]
      } else {
        return this.innerValue.labours
          .filter((i) => i.itemType === ItemCategoryTypes.RR)
          .filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, this.innerValue.quotingMethod))
      }
    },
    rwaItems() {
      if (this.hasPendingRequest) {
        const submittedItems = this.lastQuoteAssessment.submittedAssessment.assessmentQuoteItems.filter((a) => a.itemType === ItemCategoryTypes.RWA)
        const newItems = this.innerValue.labours.filter((i) => i.itemType === ItemCategoryTypes.RWA).filter((i) => i.isAdded === true && i.deleted === false)
        return [...submittedItems, ...newItems]
      } else {
        return this.innerValue.labours
          .filter((i) => i.itemType === ItemCategoryTypes.RWA)
          .filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, this.innerValue.quotingMethod))
      }
    },
    repairItems() {
      if (this.hasPendingRequest) {
        const submittedItems = this.lastQuoteAssessment.submittedAssessment.assessmentQuoteItems.filter((a) => a.itemType === ItemCategoryTypes.REP)
        const newItems = this.innerValue.labours.filter((i) => i.itemType === ItemCategoryTypes.REP).filter((i) => i.isAdded === true && i.deleted === false)
        return [...submittedItems, ...newItems]
      } else {
        return this.innerValue.labours
          .filter((i) => i.itemType === ItemCategoryTypes.REP)
          .filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, this.innerValue.quotingMethod))
      }
    },
    paintItems() {
      if (this.hasPendingRequest) {
        const submittedItems = this.lastQuoteAssessment.submittedAssessment.assessmentQuoteItems.filter((a) => a.itemType === ItemCategoryTypes.PAINT)
        const newItems = this.innerValue.labours.filter((i) => i.itemType === ItemCategoryTypes.PAINT).filter((i) => i.isAdded === true && i.deleted === false)
        return [...submittedItems, ...newItems]
      } else {
        return this.innerValue.labours
          .filter((i) => i.itemType === ItemCategoryTypes.PAINT)
          .filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, this.innerValue.quotingMethod))
      }
    },
    mechItems() {
      if (this.hasPendingRequest) {
        const submittedItems = this.lastQuoteAssessment.submittedAssessment.assessmentQuoteItems.filter((a) => a.itemType === ItemCategoryTypes.MECH)
        const newItems = this.innerValue.others.filter((i) => i.itemType === ItemCategoryTypes.MECH).filter((i) => i.isAdded === true && i.deleted === false)
        return [...submittedItems, ...newItems]
      } else {
        return this.innerValue.others
          .filter((i) => i.itemType === ItemCategoryTypes.MECH)
          .filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, this.innerValue.quotingMethod))
      }
    },
    cdItems() {
      if (this.hasPendingRequest) {
        const submittedItems = this.lastQuoteAssessment.submittedAssessment.assessmentQuoteItems.filter((a) => a.itemType === ItemCategoryTypes.CD)
        const newItems = this.innerValue.others.filter((i) => i.itemType === ItemCategoryTypes.CD).filter((i) => i.isAdded === true && i.deleted === false)
        return [...submittedItems, ...newItems]
      } else {
        return this.innerValue.others
          .filter((i) => i.itemType === ItemCategoryTypes.CD)
          .filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, this.innerValue.quotingMethod))
      }
    },
    crushItems() {
      if (this.hasPendingRequest) {
        const submittedItems = this.lastQuoteAssessment.submittedAssessment.assessmentQuoteItems.filter((a) => a.itemType === ItemCategoryTypes.CRUSH)
        const newItems = this.innerValue.others.filter((i) => i.itemType === ItemCategoryTypes.CRUSH).filter((i) => i.isAdded === true && i.deleted === false)
        return [...submittedItems, ...newItems]
      } else {
        return this.innerValue.others
          .filter((i) => i.itemType === ItemCategoryTypes.CRUSH)
          .filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, this.innerValue.quotingMethod))
      }
    },
    fgItems() {
      if (this.hasPendingRequest) {
        const submittedItems = this.lastQuoteAssessment.submittedAssessment.assessmentQuoteItems.filter((a) => a.itemType === ItemCategoryTypes.FIBER)
        const newItems = this.innerValue.others.filter((i) => i.itemType === ItemCategoryTypes.FIBER).filter((i) => i.isAdded === true && i.deleted === false)
        return [...submittedItems, ...newItems]
      } else {
        return this.innerValue.others
          .filter((i) => i.itemType === ItemCategoryTypes.FIBER)
          .filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, this.innerValue.quotingMethod))
      }
    },
    partItems() {
      if (this.hasPendingRequest) {
        const submittedItems = this.lastQuoteAssessment.submittedAssessment.assessmentQuoteItems.filter((a) => a.itemType === ItemCategoryTypes.PART)
        const newItems = this.innerValue.parts.filter((i) => i.itemType === ItemCategoryTypes.PART).filter((i) => i.isAdded === true && i.deleted === false)
        return [...submittedItems, ...newItems]
      } else {
        return this.innerValue.parts
          .filter((i) => i.itemType === ItemCategoryTypes.PART)
          .filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, this.innerValue.quotingMethod))
      }
    },
    opgItems() {
      if (this.hasPendingRequest) {
        const submittedItems = this.lastQuoteAssessment.submittedAssessment.assessmentQuoteItems.filter((a) => a.itemType === ItemCategoryTypes.OPG)
        const newItems = this.innerValue.opgs.filter((i) => i.itemType === ItemCategoryTypes.OPG).filter((i) => i.isAdded === true && i.deleted === false)
        return [...submittedItems, ...newItems]
      } else {
        return this.innerValue.opgs
          .filter((i) => i.itemType === ItemCategoryTypes.OPG)
          .filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, this.innerValue.quotingMethod))
      }
    },
    miscItems() {
      if (this.hasPendingRequest) {
        const submittedItems = this.lastQuoteAssessment.submittedAssessment.assessmentQuoteItems.filter((a) => a.itemType === ItemCategoryTypes.MISC)
        const newItems = this.innerValue.miscs.filter((i) => i.itemType === ItemCategoryTypes.MISC).filter((i) => i.isAdded === true && i.deleted === false)
        return [...submittedItems, ...newItems]
      } else {
        return this.innerValue.miscs
          .filter((i) => i.itemType === ItemCategoryTypes.MISC)
          .filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, this.innerValue.quotingMethod))
      }
    },
    subletItems() {
      if (this.hasPendingRequest) {
        const submittedItems = this.lastQuoteAssessment.submittedAssessment.assessmentQuoteItems.filter((a) => a.itemType === ItemCategoryTypes.SUBL)
        const newItems = this.innerValue.sublets.filter((i) => i.itemType === ItemCategoryTypes.SUBL).filter((i) => i.isAdded === true && i.deleted === false)
        return [...submittedItems, ...newItems]
      } else {
        return this.innerValue.sublets
          .filter((i) => i.itemType === ItemCategoryTypes.SUBL)
          .filter((i) => this.itemChanged(i, i.lastQuotedAssessment, i.lastAuthorisedAssessment, this.innerValue.quotingMethod))
      }
    },
    selectedItems() {
      return this.allActiveItems.filter((i) => i.isSelected === true)
    },
    totalSelectedItems() {
      if (this.selectedItems.length === 0) return 0
      return this.calculateItemsTotal(this.value.quotingMethod, this.selectedItems)
    },
    requestAuthorityPending() {
      if (!this.hasAssessment) return false
      return this.lastQuoteAssessment.authorisedAssessment === null
    },
    allActiveItems() {
      return [
        ...this.rrItems,
        ...this.rwaItems,
        ...this.repairItems,
        ...this.paintItems,
        ...this.mechItems,
        ...this.cdItems,
        ...this.crushItems,
        ...this.fgItems,
        ...this.partItems,
        ...this.opgItems,
        ...this.miscItems,
        ...this.subletItems
      ]
    },
    tabs() {
      return this.companyItemTypes.filter((i) => i.enabled === true)
    },
    allItemSelected: {
      get() {
        const result = this.allActiveItems.length > 0 && this.allActiveItems.every((i) => i.isSelected === true)
        return result
      },
      set(newValue) {
        this.allActiveItems.forEach((i) => {
          i.isSelected = newValue
        })
      }
    },
    targetValueColor() {
      if (this.targetValue > this.totalSelectedItems) {
        return 'has-text-danger'
      } else if (this.targetValue === this.totalSelectedItems) {
        return 'has-text-success'
      } else {
        return ''
      }
    }
  },
  watch: {
    value(newVal) {
      this.innerValue = _cloneDeep(newVal)
    },
    vehicle(newVal) {
      this.innerVehicle = _cloneDeep(newVal)
    },
    innerValue(newVal) {
      this.getLabourItemsAssessments()
      this.getOtherLabourItemsAssessments()
      this.getPartItemsAssessments()
      this.getOpgItemsAssessments()
      this.getMiscItemsAssessments()
      this.getSubletItemsAssessments()
      this.selectInitialTab()
    }
  },
  created() {
    this.innerValue = _cloneDeep(this.value)
    this.innerVehicle = _cloneDeep(this.vehicle)
    this.getLabourItemsAssessments()
    this.getOtherLabourItemsAssessments()
    this.getPartItemsAssessments()
    this.getOpgItemsAssessments()
    this.getMiscItemsAssessments()
    this.getSubletItemsAssessments()
    this.selectInitialTab()
  },
  updated() {},
  mounted() {},
  methods: {
    onPageChange() {},
    sort() {},
    tabClicked(type) {
      this.activeTab = type
    },
    selectInitialTab() {
      if (this.activeTab !== '') return
      if (this.rrItems.length > 0) {
        this.activeTab = ItemCategoryTypes.RR
      } else if (this.rwaItems.length > 0) {
        this.activeTab = ItemCategoryTypes.RWA
      } else if (this.repairItems.length > 0) {
        this.activeTab = ItemCategoryTypes.REP
      } else if (this.paintItems.length > 0) {
        this.activeTab = ItemCategoryTypes.PAINT
      } else if (this.mechItems.length > 0) {
        this.activeTab = ItemCategoryTypes.MECH
      } else if (this.cdItems.length > 0) {
        this.activeTab = ItemCategoryTypes.CD
      } else if (this.crushItems.length > 0) {
        this.activeTab = ItemCategoryTypes.CRUSH
      } else if (this.fgItems.length > 0) {
        this.activeTab = ItemCategoryTypes.FIBER
      } else if (this.partItems.length > 0) {
        this.activeTab = ItemCategoryTypes.PART
      } else if (this.opgItems.length > 0) {
        this.activeTab = ItemCategoryTypes.OPG
      } else if (this.miscItems.length > 0) {
        this.activeTab = ItemCategoryTypes.MISC
      } else if (this.subletItems.length > 0) {
        this.activeTab = ItemCategoryTypes.SUBL
      } else if (this.tabs.length > 0) {
        this.activeTab = this.tabs[0].itemCategoryType
      } else {
        this.activeTab = ''
      }
    },
    editableQuote(toAuthorise) {
      if (toAuthorise) {
        this.getTargetValue()
      }
      this.isEditable = !this.isEditable
      this.isEditableToAuthorise = toAuthorise
      this.$emit('save')
      // this.defaultSelectedItems()
    },
    openAuthoriseModal() {
      this.isAuthoriseModalVisible = true
    },
    openEditRatesModal() {
      this.isEditRateModalVisible = true
    },
    openRevertConfirmModal() {
      this.isRevertConfirmModalActive = true
    },
    openCancelAuthorityConfirmModal() {
      this.isCancelAuthorityConfirmModalActive = true
    },
    openCancelRequestConfirmModal() {
      this.isCancelRequestConfirmModalActive = true
    },
    cancel() {
      this.isEditable = false
      this.isEditableToAuthorise = false
      this.innerValue = _cloneDeep(this.value)
    },
    revertToAuthority() {
      this.$emit('revert-authority', this.innerValue.quoteId)
      this.isRevertConfirmModalActive = false
    },
    cancelAuthority(merge) {
      this.$emit('cacnel-authority', this.innerValue.quoteId, merge)
      this.isCancelAuthorityConfirmModalActive = false
    },
    cancelRequest(merge) {
      this.$emit('cacnel-request', this.innerValue.quoteId, merge)
      this.isCancelRequestConfirmModalActive = false
    },
    authorise() {
      const assessment = this.createAssessment(true)
      this.$emit('authorise-quote', this.innerValue.quoteId, assessment)
      this.isEditable = false
      this.isEditableToAuthorise = false
    },
    request() {
      const assessment = this.createAssessment(false)
      this.$emit('request-authority-quote', this.innerValue.quoteId, assessment)
      this.isEditable = false
      this.isEditableToAuthorise = false
    },
    applyRates() {
      this.rrItems.forEach((i) => {
        i.rate = this.innerValue.rates.find((r) => r.labourCodeId === LabourItemCodeTypes.RR).rate
      })
      this.mechItems.forEach((i) => {
        i.rate = this.innerValue.rates.find((r) => r.labourCodeId === LabourItemCodeTypes.MECH).rate
      })
      this.repairItems.forEach((i) => {
        i.rate = this.innerValue.rates.find((r) => r.labourCodeId === LabourItemCodeTypes.REP).rate
      })
      this.paintItems.forEach((i) => {
        i.rate = this.innerValue.rates.find((r) => r.labourCodeId === this.innerVehicle.paintGroup).rate
      })
      this.isEditRateModalVisible = false
    },
    createAssessment(authorise) {
      const assessment = {
        quoteId: this.innerValue.quoteId
      }
      if (authorise) {
        assessment.assessorName = this.innerValue.assessorName
        assessment.submittedAssessment = this.createAssessmentDetails()
        assessment.authorisedAssessment = this.createAssessmentDetails()
      } else {
        assessment.assessorName = null
        assessment.submittedAssessment = this.createAssessmentDetails()
        assessment.authorisedAssessment = null
      }
      return assessment
    },
    createAssessmentDetails() {
      let assessmentDetail = {
        claimNo: this.innerValue.claimNo,
        excess: this.innerValue.excess,
        totalExGST: this.innerValue.totalExGst,
        assessmentQuoteItems: this.createAssessmentQuoteItems()
      }
      assessmentDetail.assessmentRates = {
        paintGroup: this.innerVehicle.paintGroup,
        labourRates: this.createAssessmentLabourRates(this.innerVehicle.paintGroup)
      }
      return assessmentDetail
    },
    createAssessmentQuoteItems() {
      return this.selectedItems.map((i) => {
        return {
          quoteItemid: i.quoteItemId,
          itemType: i.itemType,
          itemNo: i.itemNo,
          itemDesc: i.itemDesc,
          hourValue: i.hourValue,
          rate: i.rate,
          dollarValue: i.dollarValue,
          partNo: i.partNo,
          partSource: i.partSource,
          itemComment: i.itemComment,
          itemQuantity: i.itemQuantity,
          value: i.value,
          reportOnly: i.reportOnly,
          isQuoteItemDeleted: i.deleted || i.isDeleted,
          markupValue: i.markupValue,
          mark: i.mark,
          buyPrice: i.buyPrice,
          lineNumber: i.lineNumber,
          opgCode: i.opgCode
        }
      })
    },
    createAssessmentLabourRates(paintGroup) {
      let rates = []
      this.innerValue.rates.forEach((rate) => {
        if (
          rate.labourCodeId === LabourItemCodeTypes.RR ||
          rate.labourCodeId === LabourItemCodeTypes.MECH ||
          rate.labourCodeId === LabourItemCodeTypes.REP ||
          rate.labourCodeId === paintGroup
        ) {
          rates.push({
            labourCodeId: rate.labourCodeId,
            rate: rate.rate
          })
        }
      })
      return rates
    },
    defaultSelectedItems() {
      this.allActiveItems.forEach((i) => {
        i.isSelected = false
      })
    },
    lastAssessment(item) {
      let assessments = []
      if (
        item.itemType === ItemCategoryTypes.RR ||
        item.itemType === ItemCategoryTypes.RWA ||
        item.itemType === ItemCategoryTypes.REP ||
        item.itemType === ItemCategoryTypes.PAINT
      ) {
        assessments = this.laboursAssessments.find((a) => a.quoteItemId === item.quoteItemId)?.assessments
      } else if (
        item.itemType === ItemCategoryTypes.MECH ||
        item.itemType === ItemCategoryTypes.CD ||
        item.itemType === ItemCategoryTypes.CRUSH ||
        item.itemType === ItemCategoryTypes.FIBER
      ) {
        assessments = this.otherLaboursAssessments.find((a) => a.quoteItemId === item.quoteItemId)?.assessments
      } else if (item.itemType === ItemCategoryTypes.PART) {
        assessments = this.partsAssessments.find((a) => a.quoteItemId === item.quoteItemId)?.assessments
      } else if (item.itemType === ItemCategoryTypes.OPG) {
        assessments = this.opgsAssessments.find((a) => a.quoteItemId === item.quoteItemId)?.assessments
      } else if (item.itemType === ItemCategoryTypes.MISC) {
        assessments = this.miscsAssessments.find((a) => a.quoteItemId === item.quoteItemId)?.assessments
      } else if (item.itemType === ItemCategoryTypes.SUBL) {
        assessments = this.subletsAssessments.find((a) => a.quoteItemId === item.quoteItemId)?.assessments
      }

      if (!assessments || assessments.length === 0) return []
      return assessments[assessments.length - 1]
    },
    rateLastAssessment(rate) {
      let assessments = this.ratesAssessments.find((a) => a.labourCodeId === rate.labourCodeId)?.assessments
      if (!assessments || assessments.length === 0) return []
      return assessments[assessments.length - 1]
    },
    getLabourItemsAssessments() {
      this.laboursAssessments = this.getItemsAssessments(this.value.labours, this.value.quoteAssessments)
    },
    getOtherLabourItemsAssessments() {
      this.otherLaboursAssessments = this.getItemsAssessments(this.value.others, this.value.quoteAssessments)
    },
    getPartItemsAssessments() {
      this.partsAssessments = this.getItemsAssessments(this.value.parts, this.value.quoteAssessments)
    },
    getOpgItemsAssessments() {
      this.opgsAssessments = this.getItemsAssessments(this.value.opgs, this.value.quoteAssessments)
    },
    getMiscItemsAssessments() {
      this.miscsAssessments = this.getItemsAssessments(this.value.miscs, this.value.quoteAssessments)
    },
    getSubletItemsAssessments() {
      this.subletsAssessments = this.getItemsAssessments(this.value.sublets, this.value.quoteAssessments)
    },
    showTab(itemCategoryType) {
      const companyItemCategoryType = this.tabs.find((i) => i.itemCategoryType === itemCategoryType)

      if (companyItemCategoryType) return true
      return false
    },
    getTargetValue() {
      const items = this.lastQuoteAssessment?.submittedAssessment.assessmentQuoteItems.filter((i) => i.deleted !== true)
      if (!items || items.length === 0) {
        this.requestedValue = 0
      }
      if (this.lastQuoteAssessment) {
        this.requestedValue = this.calculateItemsTotal(this.innerValue.quotingMethod, items)
      }
    }
  }
}
</script>
